import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addEmployeeReport, updateEmployeeReport } from "../../services/dailyReporting";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";

const CourseDepartment = () => {
  const { state } = useLocation();
  
  const navigate = useNavigate();
  
  let item = state?.item;
  let userId = state?.userId;
  let edit = state?.edit;
  const [checked, setChecked] = useState(false);
  const [input, setInput] = useState({
    createdDate: new Date(),
    todayCommitment: "",
    no_of_new_lead: "",
    no_of_call: "",
    no_of_sale: "",
  });

  useEffect(() => {
    if (edit) {
      setInput({
        createdDate: new Date(edit.createdDate) || "",
        todayCommitment: edit.data.todayCommitment || "",
        no_of_new_lead: edit.data.no_of_new_lead || "",
        no_of_call: edit.data.no_of_call || "",
        no_of_sale: edit.data.no_of_sale || "",
      });
    }
  }, [edit]);

 const [errors, setErrors] = useState({});

const validate = () => {
  const newErrors = {};

  if (!input.createdDate) {
    newErrors.createdDate = "Date of Creation can never be empty";
  }

  if (!input.todayCommitment) {
    newErrors.todayCommitment = "Today's Commitment can never be empty";
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
 const validateEdit = () => {
   const newErrors = {};

    if (!input.createdDate) {
      newErrors.createdDate = "Date of Creation can never be empty";
    }

    if (!input.todayCommitment) {
      newErrors.todayCommitment = "Today's Commitment can never be empty";
    }
   if (!input.no_of_new_lead) {
     newErrors.no_of_new_lead = "No. Of New Lead is required";
   } else if (input.no_of_new_lead < 0) {
     newErrors.no_of_new_lead = "No. Of New Lead cannot be less than 0";
   }

   if (!input.no_of_call) {
     newErrors.no_of_call = "No. Of Call is required";
   } else if (input.no_of_call < 0) {
     newErrors.no_of_call = "No. Of Call cannot be less than 0";
   }

   if (!input.no_of_sale) {
     newErrors.no_of_sale = "No. Of Sale is required";
   } else if (input.no_of_sale < 0) {
     newErrors.no_of_sale = "No. Of Sale cannot be less than 0";
   }

   setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   if (edit) {
     if (!validateEdit()) {
       return;
     }
   } else {
     if (!validate()) {
       return;
     }
   }
   const { createdDate, ...filteredInput } = input;
    filteredInput.sendReportStatus = checked ? "yes" : "no";
   const data = {
     createdDate: new Date(input.createdDate).toISOString(),
     data: filteredInput,
     reportType: item,
     user: {
       id: userId,
     },
   };

   
    const updateData = {
      createdDate: new Date(input.createdDate).toISOString(),
      data: filteredInput,
      reportType: item,
      user: {
        id: userId,
      },
      id: edit?.id,
    };


   try {
    let response;
    if (edit) {
      response = await updateEmployeeReport(updateData); // Update the API call for editing
    } else {
      response = await addEmployeeReport(data);
    }
    if (!response.data.error) {
      if (response.data.success === false) {
        errorMessage(response.data.message);
      } else {
        successMessage(response.data.message);
      }
       setInput({
         createdDate: "",
         todayCommitment:"",
         no_of_new_lead: "",
         no_of_call: "",
         no_of_sale: "",
       });
       navigate("/daily-reports", {
         state: {userId},
       });
    } else {
      errorMessage(response.data.errors.errors[0].message);
    }
   } catch (err) {
     errorMessage();
   }
 };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {item.replace(/_/g, " ")} {edit ? "Edit" : "Create"}
                </h5>
                <h6
                  className="card-title"
                  style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                >
                  Today's Commitment
                </h6>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="createdDate">Date of Creation</label>
                        <Calendar
                          id="createdDate"
                          name="createdDate"
                          value={input.createdDate}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              createdDate: e.target.value,
                            });
                          }}
                          showIcon
                          maxDate={new Date()}
                          disabled={true}
                        />
                        <div className="text-danger">{errors.createdDate}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Today's Commitment</label>
                        <textarea
                          type="number"
                          name="todayCommitment"
                          value={input.todayCommitment}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              todayCommitment: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.todayCommitment && (
                          <div className="text-danger">
                            {errors.todayCommitment}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {edit && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flex: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6
                          className="card-title"
                          style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                        >
                          Today's Achievement
                        </h6>
                        <div
                          style={{
                            marginTop: 20,
                            display: "flex",
                            flex: "row",
                          }}
                        >
                          <label style={{ marginRight: 10 }}>
                            Send This Report To WhatsApp
                          </label>
                          <InputSwitch
                            checked={checked}
                            onChange={(e) => setChecked(e.value)}
                          />
                        </div>
                      </div>
                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>No. Of New Lead</label>
                            <input
                              type="number"
                              name="no_of_new_lead"
                              value={input.no_of_new_lead}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  no_of_new_lead: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.no_of_new_lead && (
                              <div className="text-danger">
                                {errors.no_of_new_lead}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>No. Of Call</label>
                            <input
                              type="number"
                              name="no_of_call"
                              value={input.no_of_call}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  no_of_call: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.no_of_call && (
                              <div className="text-danger">
                                {errors.no_of_call}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>No. Of Sale</label>
                            <input
                              type="number"
                              name="no_of_sale"
                              value={input.no_of_sale}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  no_of_sale: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.no_of_sale && (
                              <div className="text-danger">
                                {errors.no_of_sale}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="row g-3 text-center">
                    <div className="col-12">
                      <button type="submit" className="btn btn-success ms-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CourseDepartment;
