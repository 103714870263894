import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addEmployeeReport, updateEmployeeReport } from "../../services/dailyReporting";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";

const ProjectReportSales = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let item = state?.item;
  let userId = state?.userId;
  let edit = state?.edit;
  const [checked, setChecked] = useState(false);
  const [input, setInput] = useState({
    createdDate: new Date(),
    no_of_new_lead: "",
    todayCommitment: "",
    no_of_new_project_report_software_booked: "",
    new_booking_amount: "",
    old_balance_recovered: "",
    total_collection: "",
  });

  useEffect(() => {
    if (edit) {
      setInput({
        createdDate: new Date(edit.createdDate) || "",
        no_of_new_lead:edit.data.no_of_new_lead || "",
        todayCommitment: edit.data.todayCommitment || "",
        no_of_new_project_report_software_booked: edit.data.no_of_new_project_report_software_booked || "",
        new_booking_amount: edit.data.new_booking_amount || "",
        old_balance_recovered: edit.data.old_balance_recovered || "",
        total_collection: edit.data.total_collection || "",
      });
    }
  }, [edit]);

  // Function to calculate the total collection
  const calculateTotalCollection = () => {
    const newBookingAmount = parseFloat(input.new_booking_amount) || 0;
    const oldBalanceRecovered = parseFloat(input.old_balance_recovered) || 0;
    return newBookingAmount + oldBalanceRecovered;
  };

  // Update total collection whenever new_booking_amount or old_balance_recovered changes
  useEffect(() => {
    setInput((prevState) => ({
      ...prevState,
      total_collection: calculateTotalCollection(),
    }));
  }, [input.new_booking_amount, input.old_balance_recovered]);
 const [errors, setErrors] = useState({});

 const validate = () => {
   const newErrors = {};

   if (!input.createdDate) {
     newErrors.createdDate = "Date of Creation can never be empty";
   }

   if (!input.todayCommitment) {
     newErrors.todayCommitment = "Today's Commitment can never be empty";
   }

   setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
 };

 const validateEdit = () => {
   const newErrors = {};

   if (!input.createdDate) {
     newErrors.createdDate = "Date of Creation can never be empty";
   }

   if (!input.todayCommitment) {
     newErrors.todayCommitment = "Today's Commitment can never be empty";
   }

   if (!input.no_of_new_lead) {
     newErrors.no_of_new_lead = "No. Of New Lead is required";
   } else if (input.no_of_new_lead < 0) {
     newErrors.no_of_new_lead = "No. Of New Lead cannot be less than 0";
   }

   if (!input.no_of_new_project_report_software_booked) {
     newErrors.no_of_new_project_report_software_booked = "No. Of New Project Report Software Booked is required";
   } else if (input.no_of_new_project_report_software_booked < 0) {
     newErrors.no_of_new_project_report_software_booked = "No. Of New Project Report Software Booked cannot be less than 0";
   }

   if (!input.new_booking_amount) {
     newErrors.new_booking_amount = "New Booking Amount is required";
   } else if (input.new_booking_amount < 0) {
     newErrors.new_booking_amount = "New Booking Amount cannot be less than 0";
   }

   if (!input.old_balance_recovered) {
     newErrors.old_balance_recovered = "Old Balance Recovered is required";
   } else if (input.old_balance_recovered < 0) {
     newErrors.old_balance_recovered =
       "Old Balance Recovered cannot be less than 0";
   }

   if (!input.total_collection) {
     newErrors.total_collection = "Total Collection is required";
   } else if (input.total_collection < 0) {
     newErrors.total_collection =
       "Total Collection cannot be less than 0";
   }

   setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   if (edit) {
     if (!validateEdit()) {
       return;
     }
   } else {
     if (!validate()) {
       return;
     }
   }

   const { createdDate, ...filteredInput } = input;
    filteredInput.sendReportStatus = checked ? "yes" : "no";

   const data = {
     createdDate: new Date(input.createdDate).toISOString(),
     data: filteredInput,
     reportType: item,
     user: {
       id: userId,
     },
   };

    const updateData = {
      createdDate: new Date(input.createdDate).toISOString(),
      data: filteredInput,
      reportType: item,
      user: {
        id: userId,
      },
      id: edit?.id,
    };


   try {
    let response;
    if (edit) {
      response = await updateEmployeeReport(updateData); // Update the API call for editing
    } else {
      response = await addEmployeeReport(data);
    }
    if (!response.data.error) {
      if (response.data.success === false) {
        errorMessage(response.data.message);
      } else {
        successMessage(response.data.message);
      }
      setInput({
        createdDate: "",
        todayCommitment: "",
        no_of_new_lead: "",
        no_of_new_project_report_software_booked: "",
        new_booking_amount: "",
        old_balance_recovered: "",
        total_collection: "",
      });
      navigate("/daily-reports", {
        state: { userId },
      });
    } else {
      errorMessage(response.data.errors.errors[0].message);
    }
     
   } catch (err) {
     errorMessage();
   }
 };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {item.replace(/_/g, " ")} {edit ? "Edit" : "Create"}
                </h5>
                <div
                  style={{
                    display: "flex",
                    flex: "row",
                    justifyContent: "space-between",
                  }}
                ></div>
                <h6
                  className="card-title"
                  style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                >
                  Today's Commitment
                </h6>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="createdDate">Date of Creation</label>
                        <Calendar
                          id="createdDate"
                          name="createdDate"
                          value={input.createdDate}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              createdDate: e.target.value,
                            });
                          }}
                          showIcon
                          maxDate={new Date()}
                          disabled={true}
                        />
                        <div className="text-danger">{errors.createdDate}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Today's Commitment</label>
                        <textarea
                          type="number"
                          name="todayCommitment"
                          value={input.todayCommitment}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              todayCommitment: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.todayCommitment && (
                          <div className="text-danger">
                            {errors.todayCommitment}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {edit && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flex: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6
                          className="card-title"
                          style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                        >
                          Today's Achievement
                        </h6>
                        <div
                          style={{
                            marginTop: 20,
                            display: "flex",
                            flex: "row",
                          }}
                        >
                          <label style={{ marginRight: 10 }}>
                            Send This Report To WhatsApp
                          </label>
                          <InputSwitch
                            checked={checked}
                            onChange={(e) => setChecked(e.value)}
                          />
                        </div>
                      </div>

                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>No. Of New Lead</label>
                            <input
                              type="number"
                              name="no_of_new_lead"
                              value={input.no_of_new_lead}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  no_of_new_lead: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.no_of_new_lead && (
                              <div className="text-danger">
                                {errors.no_of_new_lead}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              No. Of New Project Report Software Booked
                            </label>
                            <input
                              type="number"
                              name="no_of_new_project_report_software_booked"
                              value={
                                input.no_of_new_project_report_software_booked
                              }
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  no_of_new_project_report_software_booked:
                                    e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.no_of_new_project_report_software_booked && (
                              <div className="text-danger">
                                {
                                  errors.no_of_new_project_report_software_booked
                                }
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>New Booking Amount</label>
                            <input
                              type="number"
                              name="new_booking_amount"
                              value={input.new_booking_amount}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  new_booking_amount: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.new_booking_amount && (
                              <div className="text-danger">
                                {errors.new_booking_amount}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Old Balance Recovered</label>
                            <input
                              type="number"
                              name="old_balance_recovered"
                              value={input.old_balance_recovered}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  old_balance_recovered: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.old_balance_recovered && (
                              <div className="text-danger">
                                {errors.old_balance_recovered}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row g-3 mb-1">
                        {" "}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Total Collection</label>
                            <input
                              type="number"
                              name="total_collection"
                              value={input.total_collection}
                              readOnly
                              className="form-control"
                            />
                            {errors.total_collection && (
                              <div className="text-danger">
                                {errors.total_collection}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="row g-3 text-center">
                    <div className="col-12">
                      <button type="submit" className="btn btn-success ms-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ProjectReportSales;
