import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addEmployeeReport, updateEmployeeReport } from "../../services/dailyReporting";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";

const DigitalMarketing = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let item = state?.item;
  let userId = state?.userId;
  let edit = state?.edit;
  const [checked, setChecked] = useState(false);
  const [input, setInput] = useState({
    createdDate: new Date(),
    todayCommitment: "",
    long_video: "",
    shorts: "",
    blog: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (edit) {
      setInput({
        createdDate: new Date(edit.createdDate) || "",
        todayCommitment: edit.data.todayCommitment || "",
        long_video: edit.data.long_video || "",
        shorts: edit.data.shorts || "",
        blog: edit.data.blog || "",
      });
    }
  }, [edit]);

  const validate = () => {
    const newErrors = {};

    if (!input.createdDate) {
      newErrors.createdDate = "Date of Creation can never be empty";
    }

    if (!input.todayCommitment) {
      newErrors.todayCommitment = "Today's Commitment can never be empty";
    }

    if (!input.todayCommitment) {
      newErrors.todayCommitment = "Today's Commitment can never be empty";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEdit = () => {
    const newErrors = {};

    if (!input.createdDate) {
      newErrors.createdDate = "Date of Creation can never be empty";
    }

    if (!input.long_video) {
      newErrors.long_video = "Long Video is required";
    } else if (input.long_video < 0) {
      newErrors.long_video = "Long Video cannot be less than 0";
    }

    if (!input.shorts) {
      newErrors.shorts = "Shorts is required";
    } else if (input.shorts < 0) {
      newErrors.shorts = "Shorts cannot be less than 0";
    }

    if (!input.blog) {
      newErrors.blog = "Blog is required";
    } else if (input.blog < 0) {
      newErrors.blog = "Blog cannot be less than 0";
    }

     
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

   if (edit) {
     if (!validateEdit()) {
       return;
     }
   } else {
     if (!validate()) {
       return;
     }
   }

   
    const { createdDate, ...filteredInput } = input;
     filteredInput.sendReportStatus = checked ? "yes" : "no";
    const data = {
      createdDate: new Date(input.createdDate).toISOString(),
      data: filteredInput,
      reportType: item,
      user: {
        id: userId,
      },
    };

    const updateData = {
      createdDate: new Date(input.createdDate).toISOString(),
      data: filteredInput,
      reportType: item,
      user: {
        id: userId,
      },
      id: edit?.id,
    };


    try {
       let response;
       if (edit) {
         response = await updateEmployeeReport(updateData); // Update the API call for editing
       } else {
         response = await addEmployeeReport(data);
       }
       if (!response.data.error) {
         if (response.data.success === false) {
           errorMessage(response.data.message);
         } else {
           successMessage(response.data.message);
         }
         setInput({
           createdDate: "",
           todayCommitment: "",
           long_video: "",
           shorts: "",
           blog: "",
         });
        navigate("/daily-reports", {
          state: { userId },
        });
       } else {
         errorMessage(response.data.errors.errors[0].message);
       }
    } catch (err) {
      errorMessage();
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {item.replace(/_/g, " ")} {edit ? "Edit" : "Create"}
                </h5>
                <h6
                  className="card-title"
                  style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                >
                  Today's Commitment
                </h6>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="createdDate">Date of Creation</label>
                        <Calendar
                          id="createdDate"
                          name="createdDate"
                          value={input.createdDate}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              createdDate: e.target.value,
                            });
                          }}
                          showIcon
                          maxDate={new Date()}
                          disabled={true}
                        />
                        <div className="text-danger">{errors.createdDate}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Today's Commitment</label>
                        <textarea
                          type="number"
                          name="todayCommitment"
                          value={input.todayCommitment}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              todayCommitment: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.todayCommitment && (
                          <div className="text-danger">
                            {errors.todayCommitment}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {edit && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flex: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6
                          className="card-title"
                          style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                        >
                          Today's Achievement
                        </h6>
                        <div
                          style={{
                            marginTop: 20,
                            display: "flex",
                            flex: "row",
                          }}
                        >
                          <label style={{ marginRight: 10 }}>
                            Send This Report To WhatsApp
                          </label>
                          <InputSwitch
                            checked={checked}
                            onChange={(e) => setChecked(e.value)}
                          />
                        </div>
                      </div>
                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Long Video</label>
                            <input
                              type="number"
                              name="long_video"
                              value={input.long_video}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  long_video: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.long_video && (
                              <div className="text-danger">
                                {errors.long_video}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Blog</label>
                            <input
                              type="number"
                              name="blog"
                              value={input.blog}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  blog: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.blog && (
                              <div className="text-danger">{errors.blog}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Shorts</label>
                            <input
                              type="number"
                              name="shorts"
                              value={input.shorts}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  shorts: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.shorts && (
                              <div className="text-danger">{errors.shorts}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="row g-3 text-center">
                    <div className="col-12">
                      <button type="submit" className="btn btn-success ms-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DigitalMarketing;
