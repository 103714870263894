import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addEmployeeReport, updateEmployeeReport } from "../../services/dailyReporting";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";

const AccountDepartment = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  let item = state?.item;
  let userId = state?.userId;
   let edit = state?.edit;
  const [input, setInput] = useState({
    createdDate: new Date(),
    todayCommitment: "",
    no_of_bill_raised: "",
    amount: "",
  });

 const [errors, setErrors] = useState({});
 useEffect(() => {
   if (edit) {
     setInput({
       createdDate: new Date(edit.createdDate) || "",
       todayCommitment: edit.data.todayCommitment || "",
       no_of_bill_raised: edit.data.no_of_bill_raised || "",
       amount: edit.data.amount || "",
     });
   }
 }, [edit]);

 const validate = () => {
   const newErrors = {};

   if (!input.createdDate) {
     newErrors.createdDate = "Date of Creation can never be empty";
   }

   if (!input.todayCommitment) {
     newErrors.todayCommitment = "Today's Commitment can never be empty";
   }

   setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
 };
 const validateEdit = () => {
   const newErrors = {};

  if (!input.createdDate) {
    newErrors.createdDate = "Date of Creation can never be empty";
  }

  if (!input.todayCommitment) {
    newErrors.todayCommitment = "Today's Commitment can never be empty";
  }

   if (!input.no_of_bill_raised) {
     newErrors.no_of_bill_raised = "No. Of Bill Raised is required";
   } else if (input.no_of_bill_raised < 0) {
     newErrors.no_of_bill_raised = "No. Of Bill Raised cannot be less than 0";
   }

   if (!input.amount) {
     newErrors.amount = "Amount is required";
   } else if (input.amount < 0) {
     newErrors.amount = "Amount cannot be less than 0";
   }

   setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   if (edit) {
     if (!validateEdit()) {
       return;
     }
   } else {
     if (!validate()) {
       return;
     }
   }
   const { createdDate, ...filteredInput } = input;
   filteredInput.sendReportStatus = checked ? "yes" : "no";

   const data = {
     createdDate: new Date(input.createdDate).toISOString(),
     data: filteredInput,
     reportType: item,
     user: {
       id: userId,
     },
   };

   const updateData = {
     createdDate: new Date(input.createdDate).toISOString(),
     data: filteredInput,
     reportType: item,
     user: {
       id: userId,
     },
     id: edit?.id,
   };

   try {
     let response;
     if (edit) {
       response = await updateEmployeeReport(updateData); // Update the API call for editing
     } else {
       response = await addEmployeeReport(data);
     }
     if (!response.data.error) {
       if (response.data.success === false) {
         errorMessage(response.data.message);
       } else {
         successMessage(response.data.message);
       }
       setInput({
         createdDate: "",
         todayCommitment: "",
         no_of_bill_raised: "",
         amount: "",
         sendReportStatus: false,
       });
        navigate("/daily-reports", {
          state: { userId },
        });
     } else {
       errorMessage(response.data.errors.errors[0].message);
     }
    
   } catch (err) {
     errorMessage();
   }
 };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {item.replace(/_/g, " ")} {edit ? "Edit" : "Create"}
                </h5>
                <h6
                  className="card-title"
                  style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                >
                  Today's Commitment
                </h6>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="createdDate">Date of Creation</label>
                        <Calendar
                          id="createdDate"
                          name="createdDate"
                          value={input.createdDate}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              createdDate: e.target.value,
                            });
                          }}
                          showIcon
                          maxDate={new Date()}
                          disabled={true}
                        />
                        <div className="text-danger">{errors.createdDate}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Today's Commitment</label>
                        <textarea
                          type="number"
                          name="todayCommitment"
                          value={input.todayCommitment}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              todayCommitment: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.todayCommitment && (
                          <div className="text-danger">
                            {errors.todayCommitment}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {edit && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flex: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6
                          className="card-title"
                          style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                        >
                          Today's Achievement
                        </h6>
                        <div style={{ marginTop: 20 ,display: "flex",
                          flex: "row",}}>
                          <label  style={{marginRight:10,  }}>Send This Report To WhatsApp</label>
                          <InputSwitch
                            checked={checked}
                            onChange={(e) => setChecked(e.value)}
                            
                          />
                        </div>
                      </div>

                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>No. Of Bill Raised</label>
                            <input
                              type="number"
                              name="no_of_bill_raised"
                              value={input.no_of_bill_raised}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  no_of_bill_raised: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.no_of_bill_raised && (
                              <div className="text-danger">
                                {errors.no_of_bill_raised}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Amount</label>
                            <input
                              type="number"
                              name="amount "
                              value={input.amount}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  amount: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.amount && (
                              <div className="text-danger">{errors.amount}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="row g-3 text-center">
                    <div className="col-12">
                      <button type="submit" className="btn btn-success ms-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AccountDepartment;
